import { Button, Link } from '../../../components/Elements';
import { Bom, useDeleteBomMutation, usePostPdxMutation } from '../../../api/bom/bomApi';
import React, { useState } from 'react';
import { toast } from 'react-hot-toast/headless';
import { HiFolderOpen } from 'react-icons/hi2';
import { RiDeleteBinLine } from 'react-icons/ri';
import ButModal from '../../../components/Modal/ButModal';

type ListBomProps = {
  boms?: Bom[];
};

function ListBom(props: ListBomProps) {
  const { boms } = props;
  const [files, setFiles] = useState<FileList | null>();

  const [deleteBom] = useDeleteBomMutation();
  const [postBom] = usePostPdxMutation();

  const onSubmit = async (e) => {
    e.preventDefault();
    // console.log(files)
    const formData = new FormData();
    console.log({ formData });
    console.log({ files });
    if (files?.length) {
      formData.append(`pdxFile`, files[0]);
      console.log(formData);
      console.log((formData.get('pdxFile') as File).name.includes('json') ? 'json' : 'pdx');
      for (var [key, value] of formData.entries()) {
        console.log(key, value);
      }
      const modeledProcess: any = await postBom({
        body: formData,
        fileType: (formData.get('pdxFile') as File).name.includes('json') ? 'json' : 'pdx',
      });
      if (modeledProcess.error) {
        // console.log(modeledProcess.error);
        toast.error('Failed to upload Bill of Materials');
      } else {
        toast.success('Bill of Materials uploaded successfully');
      }
    }
  };

  const handleDelete = async (bomId: string) => {
    const response: any = await deleteBom({ bomId: bomId });

    if (response.error) {
      console.log(response.error);
      toast.error(`Failed to delete Bill of Materials: ${response.error.data.message}`);
    } else {
      toast.success('Bill of Materials deleted successfully');
    }
  };

  const renderFileList = () => (
    <ol className="grid grid-cols-3 bg-blue-300 mx-3 p-2 mt-2 rounded">
      {[...(files ?? [])].map((f: any, i: number) => (
        <li key={i} className="col-span-1 my-1 m-2 rounded bg-white p-2">
          {f.name}
        </li>
      ))}
    </ol>
  );

  return (
    <div className="overflow-hidden bg-white shadow sm:rounded mt-3">
      <form onSubmit={onSubmit} className={'mt-3 mx-3 flex'}>
        <div className="flex-1">
          <div className="h-36 w-full relative border-2 rounded border-blue-200 border-dashed border-r-0">
            <div className={' absolute h-36 w-full'}>
              <div className="flex flex-col items-center justify-center pb-6 pt-5">
                <svg
                  className="mb-4 h-8 w-8 text-gray-500 dark:text-gray-400"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 16"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                  />
                </svg>
                <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                  <span className="font-semibold">Click to upload</span> or drag and drop
                </p>
                <p className="text-xs text-gray-500 dark:text-gray-400">PDX file</p>
              </div>
            </div>
            <input
              className="absolute h-36 w-full z-10 bg-transparent text-transparent cursor-pointer"
              accept="zip/*"
              type="file"
              onChange={(e) => setFiles(e.target.files)}
              multiple
            />
          </div>
        </div>
        <Button className="h-36 rounded-l-none items-center" type="submit" value="Upload">
          Upload
        </Button>
      </form>
      {files ? <div>{renderFileList()}</div> : null}
      <ul className="divide-y divide-gray-200 mt-3">
        {!!boms?.length ? (
          boms.map((bom: Bom) => {
            if (!bom) {
              return null;
            }
            return (
              <li key={bom.id}>
                <div className="grid grid-cols-12">
                  <Link to={`./inspect/${bom.id}`} className="block col-span-10 hover:bg-blue-50 p-4">
                    <div className="flex items-center justify-between">
                      <p className="truncate text-sm font-medium text-blue-600">{bom.description}</p>
                    </div>
                  </Link>
                  <ButModal
                    label={<RiDeleteBinLine className="h-6 w-6" />}
                    submitLabel={'Yes'}
                    confirm={() => handleDelete(bom.id as string)}
                    className={'flex col-span-2 items-center hover:bg-red-100 border-0 text-red-500 rounded-none'}
                  >
                    <h2 className="text-xl text-gray-700 mb-4 mt-1 px-4 text-center">
                      Are you sure you want to delete the "{bom?.description}" Bill of materials?{' '}
                    </h2>
                  </ButModal>
                </div>
              </li>
            );
          })
        ) : (
          <div className="text-center">
            <HiFolderOpen className={'mx-auto mt-4 h-12 w-12 text-gray-400'} aria-hidden="true" />
            <h3 className="my-2 text-sm font-semibold text-gray-900">No Bill of materials</h3>
          </div>
        )}
      </ul>
    </div>
  );
}

export default ListBom;
